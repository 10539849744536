import React from 'react';
import * as ReactGA from 'react-ga';
import Header from '../components/Shared/Header';
import Footer from '../components/Shared/Footer';
import ThankYou from '../components/ThankYou';

class Index extends React.Component {
  componentDidMount() {
    ReactGA.set({
      title: 'ContactSubmission.Page',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <>
        <Header position="fixed" />
        <ThankYou />
        <Footer showContactSection={false} />
      </>
    );
  }
}

export default Index;
