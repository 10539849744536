import React from 'react';
import {Helmet} from 'react-helmet';
import * as ReactGA from 'react-ga';
import {useLocation} from '@reach/router';
import FeaturedProjectCard from '../Shared/FeaturedProjectCard/FeaturedProjectCard';
import * as classes from './thank-you.module.css';
import {Link} from 'gatsby';
import queryString from 'query-string';

const PAPERFORM_PREFILL_KEYS = ['9n1vg', '91l9g', 'dpbcv'];

function ThankYou() {
  const [prefillQuery, setPrefillQuery] = React.useState({});
  const hiddenFormRef = React.useRef();
  const {state: contactPageState} = useLocation();

  const prefillQueryString = queryString.stringify(
    {
      ethld: `${prefillQuery['9n1vg'] || ''} ${prefillQuery['dpbcv'] || ''}`.trim(),
      e90a2: prefillQuery['91l9g'],
    },
    {skipNull: true, skipEmptyString: true}
  );

  React.useEffect(() => {
    if (contactPageState?.contactData?.data) {
      const queryObj = {};
      contactPageState.contactData.data.forEach(fieldObj => {
        if (PAPERFORM_PREFILL_KEYS.includes(fieldObj.key)) {
          queryObj[fieldObj.key] = fieldObj.value;
        }
      });
      setPrefillQuery(queryObj);
    }
  }, [contactPageState]);

  React.useEffect(() => {
    const {name, country, description} = hiddenFormRef.current;

    if (contactPageState?.contactData?.data) {
      // add values into hidden form fields
      contactPageState.contactData.data.forEach(fieldObj => {
        if (fieldObj.key === name.dataset.key) {
          name.setAttribute('value', fieldObj.value);
        } else if (fieldObj.key === country.dataset.key) {
          country.setAttribute('value', fieldObj.value);
        } else if (fieldObj.key === description.dataset.key) {
          description.setAttribute('value', fieldObj.value);
        }
        // budget key
        else if (fieldObj.key === '10i5j') {
          ReactGA.event({
            category: 'ContactUs',
            action: 'Contact Form Submission',
            label: `Client Budget - ${fieldObj.value}`,
            value: fieldObj.value === 'No' ? 0 : 5,
          });
        }
      });
    }
  }, [contactPageState, hiddenFormRef]);

  return (
    <div className={classes.thankYouPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Thank You | Tintash</title>
      </Helmet>
      {/* off screen form */}
      <form ref={hiddenFormRef} aria-hidden="true" id="hidden-contact" action="#!" tabIndex="-1">
        <input tabIndex="-1" type="text" name="name" data-key="9n1vg" id="name" />
        <input tabIndex="-1" type="text" name="country" data-key="2mac4" id="country" />
        <input tabIndex="-1" type="text" name="description" data-key="apuhb" id="description" />
      </form>
      <section className={classes.header}>
        <div className="d-flex flex-column align-items-center">
          <h1 className={`${classes.heading} text-center`}>Thank you for signing up!</h1>
          <p className={`${classes.paragraph} text-center`}>
            As the next step, we’re going to prepare a panel of experts to hop on an intro call with
            you.
          </p>
          <p className={`${classes.paragraph} text-center`}>
            To expedite the process, please share a few more quick details in this form.
          </p>
          <Link className={classes.button} to={`/contact/follow-up/?${prefillQueryString}`}>
            View Form
          </Link>
          <p className={`${classes.paragraph} text-center`}>
            We've also shared this form with you over an email, in case you'd like to fill it later.
          </p>
        </div>
        <div className={classes.scrollContainer}>
          <img
            onClick={e => {
              // e.target.scrollIntoView({
              //   behavior: "smooth",
              // })
              const y = e.target.getBoundingClientRect().top + window.pageYOffset + 90;

              window.scrollTo({top: y, behavior: 'smooth'});
            }}
            className={classes.scrollIcon}
            tabindex="0"
            role="button"
            src={require('../../assets/images/thank-you/scroll-down.svg').default}
            alt="Down"
          />
          <div className={classes.scrollText}>scroll down</div>
        </div>
      </section>
      <section class={`container ${classes.projectSection}`}>
        <h2 className={`${classes.subHeading} text-center mx-auto`}>
          While you wait, take a look at some of the amazing client projects we've worked on:
        </h2>
        <div className={classes.pojectsGutterChild}>
          <div>
            <FeaturedProjectCard
              projectName="Beat Bugs"
              caseStudyUrl="/portfolio/beatbugs"
              featuredImageSrc={
                require('../../assets/images/thank-you/projects/featured-images/beat-bugs.png')
                  .default
              }
              projectDescription="Ranked number 5 in Best-selling Music app category."
              solutionCategory="Game Development"
              logoSrc={
                require('../../assets/images/thank-you/projects/logos/beat-bugs.png').default
              }
            />
          </div>
          <div>
            <FeaturedProjectCard
              projectName="Decorist"
              caseStudyUrl="/portfolio/decorist-room-builder"
              featuredImageSrc={
                require('../../assets/images/thank-you/projects/featured-images/decorist.png')
                  .default
              }
              projectDescription="An online interior design service that matches you."
              solutionCategory="Web Development"
              logoSrc={require('../../assets/images/thank-you/projects/logos/decorist.png').default}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default ThankYou;
