import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

import * as classes from './FeaturedProjectCard.module.css';

function FeaturedProjectCard({
  projectName,
  projectDescription,
  logoSrc,
  featuredImageSrc,
  solutionCategory,
  caseStudyUrl,
  linkText,
}) {
  return (
    <article style={{backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0)), url(${featuredImageSrc})`}} className={classes.card}>
      <header>
        <div>
          <img className={classes.logo} src={logoSrc} alt={projectName} />
        </div>
        <h1 className={`${classes.categoryText}`}>{solutionCategory}</h1>
      </header>
      <section>
        <p className={`${classes.projectDescription} ar-hyphenate`}>{projectDescription}</p>
      </section>
      <footer>
        <Link className={classes.casestudyLink} to={caseStudyUrl}>
          <span className="mr-2">{linkText ?? 'View Casestudy'}</span>
          <svg
            width="21"
            height="12"
            viewBox="0 0 21 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.11358 5.34386L18.332 5.30243L14.6914 2.04963C14.446 1.82173 14.4256 1.4488 14.6505 1.20017C14.8755 0.951553 15.2437 0.930835 15.4891 1.15874L20.2955 5.50961C20.4182 5.6132 20.5 5.77895 20.5 5.9447C20.5 6.11045 20.4386 6.27619 20.3159 6.37979L15.5096 10.8343C15.3868 10.9378 15.2437 11 15.1005 11C14.9573 11 14.7937 10.9171 14.671 10.7928C14.446 10.5442 14.4665 10.1713 14.7119 9.94336L18.4343 6.5041L1.09313 6.54553C0.765885 6.54553 0.5 6.27619 0.5 5.9447C0.5 5.6132 0.765885 5.34386 1.11358 5.34386Z"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0.3"
            />
          </svg>
        </Link>
      </footer>
    </article>
  );
}

FeaturedProjectCard.propTypes = {
  logoSrc: PropTypes.string.isRequired,
  featuredImageSrc: PropTypes.string.isRequired,
  solutionCategory: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  projectDescription: PropTypes.string.isRequired,
  caseStudyUrl: PropTypes.string.isRequired,
};

export default FeaturedProjectCard;
