// extracted by mini-css-extract-plugin
export var button = "thank-you-module--button---HVMK";
export var header = "thank-you-module--header--W9JxS";
export var heading = "thank-you-module--heading--ZCWGH";
export var icon = "thank-you-module--icon--GpXwJ";
export var iconContainer = "thank-you-module--icon-container--hCkk8";
export var offScreen = "thank-you-module--off-screen--aT2Xm";
export var paragraph = "thank-you-module--paragraph--WF4hN";
export var pojectsGutterChild = "thank-you-module--pojects-gutter-child--+aSV8";
export var projectSection = "thank-you-module--project-section--dc2Ts";
export var scrollContainer = "thank-you-module--scroll-container--XPqec";
export var scrollIcon = "thank-you-module--scroll-icon--H1A+H";
export var scrollText = "thank-you-module--scroll-text---AanC";
export var subHeading = "thank-you-module--sub-heading--drQya";
export var thankYouPage = "thank-you-module--thank-you-page--pWhid";